import clsx from 'clsx';
import { Row, Col } from 'react-grid-system';
import { useLocation, Link, useParams } from 'react-router-dom';

import { UNIVERSITY } from '@/config/constants';

import Header from '../components/Header';
import LayoutContainer from '../components/LayoutContainer';
import Main from '../components/Main';
//import LayoutGrid from "../components/LayoutGrid";
import styles from '../styles/Home.module.scss';

import { getStyle } from './util';
export default function Home() {
  const { search } = useLocation();

  const { name: slashName, id: slashLeadId, uni: slashUni } = useParams();

  //console.log('>>> useParams', useParams());
  console.log(':> SLASH NAME', slashName);

  console.log('>>>> SLASH ELADID', slashLeadId);
  console.log('>>>> SLASH uni', slashUni);

  const params = new URLSearchParams(search);

  const hashName = params.get('name');
  const hashUni = params.get('uni');
  const hashId = params.get('leadId');

  console.log(' Hash name leadId Uni', hashName, hashId, hashUni);

  const linkToOpen = import.meta.env.VITE_APP_SKIP_AND_ENQUIRE_ONLY_LINK;

  let leadIdExists = false;
  let nameExists = false;
  let uniExists = false;

  let name;
  let uni;

  localStorage.removeItem('name');
  localStorage.removeItem('leadId');
  localStorage.removeItem('uni');

  if (!slashName && !slashLeadId && !slashUni) {
    console.log('>> OTHER UNI');
    if (hashUni) {
      localStorage.setItem('uni', hashUni);
      uniExists = true;
      uni = hashUni;
    }
    if (hashName) {
      localStorage.setItem('name', hashName);
      nameExists = true;
      name = hashName;
    }

    if (hashId) {
      localStorage.setItem('leadId', hashId);
      leadIdExists = true;
    }
  }

  if (!hashUni && !hashName && !hashId) {
    console.log(' JAIN ONLY');
    if (slashUni) {
      localStorage.setItem('uni', slashUni);
      uniExists = true;
      uni = slashUni;
    }

    if (slashName) {
      localStorage.setItem('name', slashName);
      nameExists = true;
      name = slashName;
    }

    if (slashLeadId) {
      localStorage.setItem('leadId', slashLeadId);
      leadIdExists = true;
    }
  }

  const getUniversityName = (university) => {
    if (UNIVERSITY.JAIN === university) {
      return `JAIN ONLINE`;
    }

    if (UNIVERSITY.AMITY === university) {
      return `AMITY ONLINE`;
    }

    if (UNIVERSITY.AMRITA === university) {
      return `AMRITA ONLINE`;
    }

    if (UNIVERSITY.CU === university) {
      return `Chandigarh University ONLINE`;
    }

    if (UNIVERSITY.DPU === university) {
      return `DY PATIL ONLINE`;
    }

    if (UNIVERSITY.MANIPAL === university) {
      return `MANIPAL ONLINE`;
    }

    if (UNIVERSITY.VIGNAN === university) {
      return `VIGNAN ONLINE`;
    }

    if (UNIVERSITY.SHARDA === university) {
      return `SHARDA ONLINE`;
    }

    if (UNIVERSITY.YENEPOYA === university) {
      return `YENEPOYA ONLINE`;
    }

    if (UNIVERSITY.SHARDA === university) {
      return `SHARDA ONLINE`;
    }
    return '';
  };

  const renderMain = () => {
    return (
      <>
        <Header university={uni} />
        <div
          className={clsx(styles.background, 'w-100', 'flex', 'align-center')}
          style={{ border: '0px solid blue' }}
        >
          <Row
            className="w-100"
            style={{ border: '0px solid black' }}
            debug={true}
          >
            <Col style={{ border: '0px solid pink' }}>
              <Row
                style={{
                  width: '100%',
                  margin: '30px 10px',
                  fontSize: '44px',
                  font: 'Roboto',
                  fontWeight: '700',
                  color: '#fff',
                }}
              >
                <Col
                  style={{
                    marginLeft: '150px',
                    display: '',
                    justifyContent: 'center',
                    border: '0px solid black',
                  }}
                >
                  <Row
                    style={{
                      margin: '0',
                      width: '100%',
                      border: '0px solid black',
                    }}
                  >
                    {`Welcome to ${getUniversityName(uni)}, ${name}`}
                  </Row>
                </Col>
              </Row>
              <Row
                gutterWidth={0}
                style={{
                  margin: '20px 0px',
                  font: 'Roboto',
                  fontSize: '27px',
                  fontWeight: '400',
                  color: '#fff',
                  marginTop: '20px',
                  border: '0px solid black',
                }}
              >
                <Col
                  style={{
                    border: '0px solid black',
                    marginLeft: '150px',
                    display: '',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Row
                    style={{
                      margin: '0 20px',
                      border: '0px solid black',
                      width: '50%', //window && window.innerWidth < 760 ? "100%" : "50%",
                    }}
                  >
                    We would like to know a little more about you to help you
                    with your choice of learning
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  border: '0px solid red',
                  margin: '30px 0px 30px 0px',
                  display: 'flex',
                  justifyContent: 'flexStart',
                }}
              >
                <Col style={{ border: '0px solid black', marginLeft: '150px' }}>
                  <Row
                    style={{
                      marginLeft: '10px',
                      marginTop: '10px',
                      width: '100%',
                      border: '0px solid black',
                      cursor: 'pointer',
                    }}
                  >
                    <Link to={`/persona`}>
                      <button
                        style={{
                          borderRadius: '4px',
                          border: '1px solid #F8C200',
                          background: '#F8C200',
                          height: '47px',
                          fontSize: '17px',
                          fontWeight: '500',
                          padding: '0 25px',
                        }}
                      >
                        {`PROCEED`}
                      </button>
                    </Link>
                    <Link to={linkToOpen}>
                      <button
                        style={{
                          borderRadius: '4px',
                          border: '1px solid #F8C200',
                          background: 'transparent',
                          color: '#F8C200',
                          height: '47px',
                          fontSize: '17px',
                          fontWeight: '500',
                          marginLeft: '20px',
                          padding: '0 25px',
                        }}
                        onClick={() => {
                          window.open(linkToOpen);
                        }}
                      >
                        {`SKIP AND APPLY NOW`}
                      </button>
                    </Link>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const renderDefaultHome = () => {
    return (
      <>
        <Header />
        <div
          className={clsx(
            styles.background,
            'w-100',
            'flex',
            'align-center',
            'justify-center',
          )}
          style={{ fontSize: '36px', color: '#fff' }}
        >{`Parameters Missing - Lead Name and Lead ID`}</div>
      </>
    );
  };

  return (
    <LayoutContainer style={{ border: '0px solid green' }}>
      <Main style={{ border: '0px solid black' }}>
        {leadIdExists && nameExists && uniExists
          ? renderMain()
          : renderDefaultHome()}
      </Main>
    </LayoutContainer>
  );
}
