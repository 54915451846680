export const BREAKPOINTS = {
  xs: 550,
  sm: 760,
  md: 1020,
  lg: 1270,
  xl: 1420,
  xxl: 1600,
};

export const UNIVERSITY = {
  AMITY: 'am',
  AMRITA: 'ao',
  CU: 'co',
  DPU: 'dp',
  JAIN: 'jo',
  MANIPAL: 'mo',
  SHARDA: 'so',
  VIGNAN: 'vo',
  YENEPOYA: 'yo',
};

export const LOCALSTORAGE = {
  ACCESS_TOKEN: 'access_token',
  LEAD_ID: 'leadId',
  UNI: 'uni',
  NAME: 'name',
  API_DATA_TO_BE_SENT_BACK: 'apiDataToBeSentBack',
  LEAD_PERSONA_DETAILS: 'leadPersonaDetails',
};

export const TOKEN_ENDPOINT_URL = import.meta.env.VITE_APP_TOKEN_ENDPOINT_URL;

export const TOKEN_ENDPOINT_XSRF_TOKEN = import.meta.env
  .VITE_APP_TOKEN_ENDPOINT_XSRF_TOKEN;

export const TOKEN_ENDPOINT_AUTHORIZATION = import.meta.env
  .VITE_APP_TOKEN_ENDPOINT_AUTHORIZATION;

export const LEAD_ENDPOINT_URL = import.meta.env.VITE_APP_LEAD_ENDPOINT_URL;

export const SUPERLEAP_WEBHOOK_URL = import.meta.env
  .VITE_APP_SUPERLEAP_WEBHOOK_URL;
