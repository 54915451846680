export const BACHELORS = {
  BCOM: 'BCOM',
  BCA: 'BCA',
  BBA: 'BBA',
  BSC: 'BSC',
  BTECH: 'BTECH',
};

export const MASTERS = {
  MCOM: 'MCOM',
  MCA: 'MCA',
  MBA: 'MBA',
  MSC: 'MSC',
  MTECH: 'MTECH',
};

export const HIGHESTEDUCATION = {
  BACHELORS: "Bachelor's",
  MASTERS: "Master's",
};

export const EXPECTEDOUTCOME = {
  SALARYHIKE: 'Salary Hike',
  DOMAINEXPERTISE: 'Domain Expertise',
  JOBCHANGE: 'Job Change',
  OTHERS: 'Others',
};

export const PREFERREDPAYMENT = {
  SELFFUNDED: 'Self-Funded',
  EMPLOYERFUNDING: 'Employer Funding',
  LOANASSISTANCE: 'Need a Loan Assistance',
};

export const WORKEXPERIENCE = {
  YEARS01: 'Fresher',
  YEARS12: '1-2 years',
  YEARS24: '2-4 years',
  YEARS46: '4-6 years',
  YEARS710: '7-10 years',
  YEARS10PLUS: '10+ years',
};

export const PERCENTAGE = {
  OTHERS: 'Others',
};

export const COURSESTART = {
  IMMEDIATELY: 'Immediately',
  NOTSURE: 'Not Sure',
  WITHINAWEEK: 'Within a Week',
  WITHIN15DAYS: 'Within 15 Days',
  WITHINAMONTH: 'Within a Month',
};

export const WORKINDUSTRY = {
  AUTOMOBILE: 'Automobile',
  BFSI: 'BFSI',
  IT: 'IT',
  EDUCATION: 'Education',
  HEALTHCARE: 'Healthcare',
  MANUFACTURING: 'Manufacturing',
  RETAIL: 'Retail',
  OTHERS: 'Others',
};

export const SALARYRANGE = {
  LAKHS3: '0-3 Lakhs',
  LAKHS6: '3-6 Lakhs',
  LAKHS12: '6-12 Lakhs',
  LAKSH12PLUS: '12 Lakhs+',
};

export const useStaticData = () => {
  const data = [
    {
      qm: 'What is your highest level of education completed?',
      qs: 'Select any one',
      response: [HIGHESTEDUCATION.BACHELORS, HIGHESTEDUCATION.MASTERS],
    },
    {
      qm: 'Which was your combination in PLACEHOLDER?',
      qs: 'Select any one',
      response: [
        BACHELORS.BCOM,
        BACHELORS.BCA,
        BACHELORS.BBA,
        BACHELORS.BSC,
        BACHELORS.BTECH,
      ],
    },
    {
      qm: 'What was your percentage in PLACEHOLDER?',
      qs: 'Enter your percentage',
      response: [PERCENTAGE.OTHERS],
    },
    {
      qm: 'How many years of work experience do you have?',
      qs: 'Select the number of years',
      response: [
        WORKEXPERIENCE.YEARS01,
        WORKEXPERIENCE.YEARS12,
        WORKEXPERIENCE.YEARS24,
        WORKEXPERIENCE.YEARS46,
        WORKEXPERIENCE.YEARS710,
        WORKEXPERIENCE.YEARS10PLUS,
      ],
    },
    {
      qm: 'Which industry are you currently working in?',
      qs: 'Select your industry below',
      response: [
        WORKINDUSTRY.IT,
        WORKINDUSTRY.BFSI,
        WORKINDUSTRY.EDUCATION,
        WORKINDUSTRY.HEALTHCARE,
        WORKINDUSTRY.RETAIL,
        WORKINDUSTRY.MANUFACTURING,
        WORKINDUSTRY.AUTOMOBILE,
        WORKINDUSTRY.OTHERS,
      ],
    },
    {
      qm: 'What is your salary range?',
      qs: ' ',
      response: [
        SALARYRANGE.LAKHS3,
        SALARYRANGE.LAKHS6,
        SALARYRANGE.LAKHS12,
        SALARYRANGE.LAKSH12PLUS,
      ],
    },
    {
      qm: 'What is your expected outcome out of the program?',
      qs: ' ',
      response: [
        EXPECTEDOUTCOME.SALARYHIKE,
        EXPECTEDOUTCOME.DOMAINEXPERTISE,
        EXPECTEDOUTCOME.JOBCHANGE,
        EXPECTEDOUTCOME.OTHERS,
      ],
    },
    {
      qm: 'How would you like to pay the fees?',
      qs: ' ',
      response: [
        PREFERREDPAYMENT.SELFFUNDED,
        PREFERREDPAYMENT.EMPLOYERFUNDING,
        PREFERREDPAYMENT.LOANASSISTANCE,
      ],
    },
    {
      qm: 'When are you planning to start your course?',
      qs: ' ',
      response: [
        COURSESTART.IMMEDIATELY,
        COURSESTART.WITHINAWEEK,
        COURSESTART.WITHIN15DAYS,
        COURSESTART.WITHINAMONTH,
        COURSESTART.NOTSURE,
      ],
    },
  ];

  return { data };
};
