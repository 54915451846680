import axios from 'axios';
import {
  TOKEN_ENDPOINT_URL,
  LOCALSTORAGE,
  LEAD_ENDPOINT_URL,
} from '@/config/constants';

const getAccessToken = async (page: string) => {
  if (page === 'persona') {
    const aT = localStorage.getItem(LOCALSTORAGE.ACCESS_TOKEN);
    if (aT) {
      console.log(' >>>>> accessToken FOUND IF --- PERSONA');

      return Promise.resolve(aT);
    } else {
      console.log(' >>>>> accessToken MISSING - ELSE --- PERSONA');
      const headers = {
        Authorization: `Basic ${btoa(import.meta.env.VITE_CLIENT_ID + ':' + import.meta.env.VITE_CLIENT_SECRET)}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      };

      const payload = {
        grant_type: 'client_credentials',
      };

      const responseTokenApi = await axios.post(TOKEN_ENDPOINT_URL, payload, {
        headers: headers,
      });

      const accessToken =
        responseTokenApi &&
        responseTokenApi.data &&
        responseTokenApi.data['access_token']
          ? responseTokenApi &&
            responseTokenApi.data &&
            responseTokenApi.data['access_token']
          : undefined;

      localStorage.setItem(LOCALSTORAGE.ACCESS_TOKEN, accessToken);
      //console.log('>>>> SETTING accessToken ', accessToken);
      return Promise.resolve(accessToken);
    }
  }

  if (page === 'thankyou') {
    return Promise.resolve(localStorage.getItem(LOCALSTORAGE.ACCESS_TOKEN));
  }

  return Promise.resolve(null);
};

const getDataFromApiToSendBack = async () => {
  const apiDataToBeSentBack = localStorage.getItem(
    LOCALSTORAGE.API_DATA_TO_BE_SENT_BACK,
  );

  console.log(
    '>?>> localStorage.getItemLOCALSTORAGE.API_DATA_TO_BE_SENT_BACK',
    localStorage.getItem(LOCALSTORAGE.API_DATA_TO_BE_SENT_BACK),
  );

  if (apiDataToBeSentBack) {
    console.log('apiDataToBeSentBack FOUND');
    return Promise.resolve(JSON.parse(apiDataToBeSentBack));
  } else {
    console.log('apiDataToBeSentBack MISSING');
    const leadId = localStorage.getItem(LOCALSTORAGE.LEAD_ID);

    const url = `${LEAD_ENDPOINT_URL}${leadId}`;

    const accessToken = localStorage.getItem(LOCALSTORAGE.ACCESS_TOKEN);

    const existingDataApiResponse = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    console.log('>><>> existingDataApiResponse', existingDataApiResponse);

    const existingData =
      existingDataApiResponse &&
      existingDataApiResponse.data &&
      existingDataApiResponse.data.data
        ? existingDataApiResponse.data.data
        : undefined;

    if (existingData) {
      const apiDataToBeSentBack = {
        name: existingData['name'],
        email: existingData['email'],
        country: existingData['country'],
      };

      localStorage.setItem(
        LOCALSTORAGE.API_DATA_TO_BE_SENT_BACK,
        JSON.stringify(apiDataToBeSentBack),
      );

      return Promise.resolve(apiDataToBeSentBack);
    }
    console.log('>>>>>>>> LINE 96 - returning null');
    return Promise.resolve(null);
  }
};
export { getAccessToken, getDataFromApiToSendBack };

