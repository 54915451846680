import { UNIVERSITY } from '@/config/constants';

import styles from '../styles/Home.module.scss';

const getStyle = (university = null) => {
  const uni = university ? university : localStorage.getItem('uni');
  if (UNIVERSITY.JAIN === uni) {
    return styles.background;
  }
  if (UNIVERSITY.AMRITA === uni) {
    return styles.backgroundAmrita;
  }
  if (UNIVERSITY.SHARDA === uni) {
    return styles.backgroundSharda;
  }
  if (UNIVERSITY.YENEPOYA === uni) {
    return styles.backgroundYenepoya;
  }

  if (UNIVERSITY.VIGNAN === uni) {
    return styles.backgroundVignan;
  }

  return styles.backgroundDefault;
};

const flattenObject = (ob) => {
  const result = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObject(ob[i]);
      for (const j in temp) {
        result[j] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }
  return result;
};
export { getStyle, flattenObject };
