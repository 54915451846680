import clsx from 'clsx';
import { Row, Col } from 'react-grid-system';

import { UNIVERSITY } from '@/config/constants';

import amity from '../../assets/amity.png';
import amrita from '../../assets/amritaonline.png';
import cu from '../../assets/cu.png';
import dpu from '../../assets/dpu.png';
import manipal from '../../assets/manipal.png';
//import styles from './Header.module.scss';
// import vignan from '../../icons/Vignann.png';
import vignan from '../../assets/vignanoline.webp';
import yenepoya from '../../assets/yenepoya.webp';
import JainLogo from '../../icons/jainLogo';
import styles from './Header.module.scss';

const Header = ({ university }) => {
  const getLogo = (uni) => {
    if ([UNIVERSITY.AMITY].includes(uni)) {
      return <img src={amity} alt={'logo'} />;
    }

    if ([UNIVERSITY.AMRITA].includes(uni)) {
      return <img src={amrita} alt={'logo'} />;
    }
    if ([UNIVERSITY.CU].includes(uni)) {
      return <img src={cu} alt={'logo'} />;
    }
    if ([UNIVERSITY.DPU].includes(uni)) {
      return <img src={dpu} alt={'logo'} />;
    }

    if ([UNIVERSITY.JAIN].includes(uni)) {
      return <JainLogo />;
    }

    if ([UNIVERSITY.MANIPAL].includes(uni)) {
      return <img src={manipal} alt={'logo'} />;
    }

    if ([UNIVERSITY.VIGNAN].includes(uni)) {
      return <img src={vignan} alt={'logo'} />;
    }
    if ([UNIVERSITY.YENEPOYA].includes(uni)) {
      return <img src={yenepoya} alt={'logo'} />;
    }
    return 'WHO ARE YOU';
  };

  const getWidth = (uni) => {
    if ([UNIVERSITY.MANIPAL].includes(uni)) {
      return `80px`;
    }
    return `200px`;
  };
  const getHeaderColor = (uni) => {
    if ([UNIVERSITY.AMITY].includes(uni)) {
      return styles.backgroundAmity;
    }

    if ([UNIVERSITY.AMRITA].includes(uni)) {
      return styles.backgroundAmrita;
    }
    if ([UNIVERSITY.CU].includes(uni)) {
      return styles.backgroundCu;
    }
    if ([UNIVERSITY.DPU].includes(uni)) {
      return styles.backgroundDpu;
    }

    if ([UNIVERSITY.JAIN].includes(uni)) {
      return '';
    }

    if ([UNIVERSITY.MANIPAL].includes(uni)) {
      return styles.backgroundManipal;
    }

    if ([UNIVERSITY.VIGNAN].includes(uni)) {
      return styles.backgroundVignan;
    }
    if ([UNIVERSITY.YENEPOYA].includes(uni)) {
      return styles.backgroundYenepoya;
    }
  };
  return (
    <Row
      className={'w-100'}
      style={{
        border: '0px solid aqua',
        height: '90px',
        position: 'absolute',
        zIndex: 10,
        margin: '0',
      }}
    >
      <Col className={clsx('flex', 'align-center')} style={{ color: '#fff' }}>
        <div
          className={getHeaderColor(university)}
          style={{ border: '0px solid red', width: getWidth(university) }}
        >
          {getLogo(university)}
        </div>
      </Col>
    </Row>
  );
};

export default Header;
