import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
  TOKEN_ENDPOINT_URL,
  LEAD_ENDPOINT_URL,
  LOCALSTORAGE,
  UNIVERSITY,
  SUPERLEAP_WEBHOOK_URL,
} from '@/config/constants';

import {
  useStaticData,
  BACHELORS,
  MASTERS,
  HIGHESTEDUCATION,
  EXPECTEDOUTCOME,
  PREFERREDPAYMENT,
  WORKEXPERIENCE,
  WORKINDUSTRY,
  COURSESTART,
  SALARYRANGE,
} from '../api/useStaticData';
import Header from '../components/Header';
import LayoutContainer from '../components/LayoutContainer';
import Main from '../components/Main';
import Automobile from '../icons/automobile';
import Bachelor from '../icons/bachelor';
import BFSI from '../icons/bfsi';
import DomainExpertise from '../icons/domainexpertise';
import Education from '../icons/education';
import EmployerFunding from '../icons/employerfunding';
import Graduate1 from '../icons/graduate1';
import Graduate2 from '../icons/graduate2';
import Graduate3 from '../icons/graduate3';
// import Graduate4 from '../icons/graduate4';
import Graduate5 from '../icons/graduate5';
import Healthcare from '../icons/healthcare';
import Immediately from '../icons/immediately';
import IT from '../icons/it';
import JobChange from '../icons/jobchange';
import Manufacturing from '../icons/manufacturing';
import Master from '../icons/master';
import NeedLoanAssistance from '../icons/needloanassistance';
import NotSure from '../icons/notsure';
import OutcomeOthers from '../icons/outcomeothers';
import Retail from '../icons/retail';
import SalaryHike from '../icons/salaryhike';
import SelfFunded from '../icons/selffunded';
import Within15Days from '../icons/within15days';
import WithinAMonth from '../icons/withinamonth';
import WithinAWeek from '../icons/withinaweek';
import styles from '../styles/Persona.module.scss';

import { getAccessToken, getDataFromApiToSendBack } from './getAccessToken';
import { flattenObject, getStyle } from './util';

const Persona = () => {
  const { data } = useStaticData();

  const [curQ, setCurQ] = useState(0);
  const navigate = useNavigate();

  const submitOrNextText = 'Next';
  const [bachelorOrMaster, setBachelorOrMaster] = useState(null);
  const [userChoiceMade, setUserChoiceMade] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [capturedData, setCapturedData] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [selectedCard, setSelectedCard] = useState(null);

  const sendData = async (university) => {
    const leadPersonaDetails = {
      highestEducationCompleted: capturedData[0],
      higestEducationCombination: capturedData[1],
      nextHighestEducationQualificationPercentage: capturedData[2],
      workExperience: capturedData[3],
      currentIndustry: capturedData[4],
      salaryRange: capturedData[5],
      expectedOutcome: capturedData[6],
      preferredPayment: capturedData[7],
      courseStart: capturedData[8],
      'surveyQuestions-getToKnow': null,
      'surveyQuestions-getToKnowOther': null,
      'surveyQuestions-willRecommend': null,
    };

    localStorage.removeItem(LOCALSTORAGE.LEAD_PERSONA_DETAILS);
    localStorage.setItem(
      LOCALSTORAGE.LEAD_PERSONA_DETAILS,
      JSON.stringify(leadPersonaDetails),
    );

    const accessToken = await getAccessToken('persona');

    //console.log(' >>>> accessToken', accessToken);
    //console.log(' >>>> leadPersonaDetails', leadPersonaDetails);

    // console.log(
    //   ' >>>> JSON.stringify leadPersonaDetails',
    //   JSON.stringify(leadPersonaDetails),
    // );
    if (accessToken) {
      // console.log(
      //   '>>>> JSON.stringify(apiDataToBeSentBack)',
      //   JSON.stringify(apiDataToBeSentBack),
      // );

      const apiDataToBeSentBack = await getDataFromApiToSendBack();

      console.log('>>>> LINE 132 apiDataToBeSentBack ', apiDataToBeSentBack);
      const toBeSentData = Object.assign(
        {},
        {
          leadPersonaDetails: leadPersonaDetails,
        },
        apiDataToBeSentBack,
      );

      console.log('>>>>> toBeSentData ', toBeSentData);

      if ([UNIVERSITY.JAIN].includes(university)) {
        console.log('>>> API CALL  - JAIN ');
        const leadId = localStorage.getItem(LOCALSTORAGE.LEAD_ID);
        const url = `${LEAD_ENDPOINT_URL}${leadId}`;
        axios
          .put(url, toBeSentData, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(() => {})
          .catch(() => {});
      }
      if (
        [
          UNIVERSITY.AMITY,
          UNIVERSITY.AMRITA,
          UNIVERSITY.CU,
          UNIVERSITY.DPU,
          UNIVERSITY.MANIPAL,
          UNIVERSITY.VIGNAN,
          UNIVERSITY.YENEPOYA,
        ].includes(university)
      ) {
        console.log('>>> API CALL  - ALL OTHER UNI ');
        const flattened = flattenObject(toBeSentData);

        console.log('>>>>  superleap REQUEST', flattened);
        const superleapResponse = await axios.post(
          SUPERLEAP_WEBHOOK_URL,
          flattened,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );

        console.log('>>>> superleapResponse', superleapResponse);
        //         curl --location 'https://staging.superleap.com/api/v1/w/quB7GV/quB7GV_yiWFsFpVOWtbaWQB77xd/' \
        // --header 'Content-Type: application/json' \
        // --data '{
        //   "programName": "MCA",
        //   "country": "IN",
        //   "phone": "+91-6374649520",
        //   "applicationDecision": "TO_BE_REVIEWED",
        //   "applicationId": "17a85226-67ab-4dfa-9412-cb4f987e5151",
        //   "batchId": "dfa97ab9-8f88-447f-a81c-997671fe05a3",
        //   "programElective": "Data Analytics"
        // }'

        //Call superleap api
      }
    }
    console.log('>>>> sendData --- END');
  };

  const buttonClicked = async () => {
    setSelectedCard(null);
    console.log(' >>>>> LINE 165 - enter buttonClicked');

    const university = localStorage.getItem(LOCALSTORAGE.UNI);

    if (curQ < 8) {
      console.log(' >>>>> LINE 169');
      if (userChoiceMade[curQ]) {
        console.log(' >>>>> LINE 171');
        if (curQ === 2) {
          const enteredValue = document.getElementById('percentage').value;

          if (enteredValue && enteredValue >= 0 && enteredValue <= 100) {
            await sendData(university);

            setCurQ(curQ + 1);
          }
        } else {
          await sendData(university);
          setCurQ(curQ + 1);
        }
      }
    } else {
      console.log(' >>>>> LINE 185');
      await sendData(university);
      navigate('/thankyou');
    }
    console.log(' >>>>> LINE 189 - eixt buttonClicked');
  };

  const renderMain = () => {
    const uni = localStorage.getItem(LOCALSTORAGE.UNI);

    const isExperienceTab = (choice) => {
      return [
        WORKEXPERIENCE.YEARS01,
        WORKEXPERIENCE.YEARS12,
        WORKEXPERIENCE.YEARS24,
        WORKEXPERIENCE.YEARS46,
        WORKEXPERIENCE.YEARS710,
        WORKEXPERIENCE.YEARS10PLUS,
      ].includes(choice);
    };

    const isExperienceSectorTab = (choice) => {
      return [
        WORKINDUSTRY.IT,
        WORKINDUSTRY.BFSI,
        WORKINDUSTRY.EDUCATION,
        WORKINDUSTRY.HEALTHCARE,
        WORKINDUSTRY.RETAIL,
        WORKINDUSTRY.MANUFACTURING,
        WORKINDUSTRY.AUTOMOBILE,
        WORKINDUSTRY.OTHERS,
      ].includes(choice);
    };

    const isSalaryTab = (choice) => {
      return [
        SALARYRANGE.LAKHS3,
        SALARYRANGE.LAKHS6,
        SALARYRANGE.LAKHS12,
        SALARYRANGE.LAKSH12PLUS,
      ].includes(choice);
    };

    const isOutcomeTab = (choice) => {
      return [
        EXPECTEDOUTCOME.SALARYHIKE,
        EXPECTEDOUTCOME.DOMAINEXPERTISE,
        EXPECTEDOUTCOME.JOBCHANGE,
        EXPECTEDOUTCOME.OTHERS,
      ].includes(choice);
    };

    const isFundingTab = (choice) => {
      return [
        PREFERREDPAYMENT.SELFFUNDED,
        PREFERREDPAYMENT.EMPLOYERFUNDING,
        PREFERREDPAYMENT.LOANASSISTANCE,
      ].includes(choice);
    };

    const isStartingTab = (choice) => {
      return [
        COURSESTART.IMMEDIATELY,
        COURSESTART.WITHINAWEEK,
        COURSESTART.WITHIN15DAYS,
        COURSESTART.WITHINAMONTH,
        COURSESTART.NOTSURE,
      ].includes(choice);
    };

    const getSvg = (choice, index, miniature = false) => {
      if (
        [HIGHESTEDUCATION.BACHELORS, HIGHESTEDUCATION.MASTERS].includes(choice)
      ) {
        return index % 2 === 0 ? <Bachelor /> : <Master />;
      }

      if (isExperienceTab(choice)) {
        return null;
      }

      if (isOutcomeTab(choice)) {
        if (index === 0) {
          return <SalaryHike />;
        }
        if (index === 1) {
          return <DomainExpertise />;
        }
        if (index === 2) {
          return <JobChange />;
        }
        if (index === 3) {
          return <OutcomeOthers />;
        }
      }

      if (isFundingTab(choice)) {
        if (index === 0) {
          return <SelfFunded />;
        }
        if (index === 1) {
          return <EmployerFunding />;
        }
        if (index === 2) {
          return <NeedLoanAssistance />;
        }
      }

      if (isStartingTab(choice)) {
        if (index === 0) {
          return <Immediately />;
        }
        if (index === 1) {
          return <WithinAWeek />;
        }
        if (index === 2) {
          return <Within15Days />;
        }
        if (index === 3) {
          return <WithinAMonth />;
        }
        if (index === 4) {
          return <NotSure />;
        }
      }

      if (isExperienceSectorTab(choice)) {
        if (index === '00') {
          if (miniature === true) {
            return <IT width={'40px'} height={'30px'} />;
          }
          //return <IT />;
          return <IT width={'40px'} height={'30px'} />;
        }
        if (index === '01') {
          //return <BFSI />;
          return <BFSI width={'40px'} height={'30px'} />;
        }
        if (index === '03') {
          return <Education width={'40px'} height={'30px'} />;
        }
        if (index === '02') {
          return <Healthcare width={'40px'} height={'30px'} />;
        }
        if (index === '10') {
          return <Retail width={'40px'} height={'30px'} />;
        }

        if (index === '11') {
          return <Manufacturing width={'40px'} height={'30px'} />;
        }
        if (index === '12') {
          return <Automobile width={'40px'} height={'30px'} />;
        }
        if (index === '13') {
          return <OutcomeOthers width={'40px'} height={'30px'} />;
        }
      }

      if (isSalaryTab(choice)) {
        return null;
      }

      if (index % 4 === 0) {
        return <Graduate1 />;
      }
      if (index % 4 === 1) {
        return <Graduate2 />;
      }
      if (index % 4 === 2) {
        return <Graduate3 />;
      }
      if (index % 4 === 3) {
        return <Graduate5 />;
      }

      return index % 2 === 0 ? <Bachelor /> : <Master />;
    };

    const getText = (choice) => {
      if (choice === WORKEXPERIENCE.YEARS01) {
        return '0-1';
      }

      if (choice === WORKEXPERIENCE.YEARS12) {
        return '1-2';
      }

      if (choice === WORKEXPERIENCE.YEARS24) {
        return '2-4';
      }
      if (choice === WORKEXPERIENCE.YEARS46) {
        return '4-6';
      }
      if (choice === WORKEXPERIENCE.YEARS710) {
        return '7-10';
      }
      if (choice === WORKEXPERIENCE.YEARS10PLUS) {
        return '10+';
      }

      if (choice === BACHELORS.BCOM) {
        if (bachelorOrMaster === HIGHESTEDUCATION.MASTERS) {
          return MASTERS.MCOM;
        }
      }
      if (choice === BACHELORS.BCA) {
        if (bachelorOrMaster === HIGHESTEDUCATION.MASTERS) {
          return MASTERS.MCA;
        }
      }
      if (choice === BACHELORS.BBA) {
        if (bachelorOrMaster === HIGHESTEDUCATION.MASTERS) {
          return MASTERS.MBA;
        }
      }
      if (choice === BACHELORS.BSC) {
        if (bachelorOrMaster === HIGHESTEDUCATION.MASTERS) {
          return MASTERS.MSC;
        }
      }
      if (choice === BACHELORS.BTECH) {
        if (bachelorOrMaster === HIGHESTEDUCATION.MASTERS) {
          return MASTERS.MTECH;
        }
      }

      return choice;
    };

    const renderCardBasedOnTab = (currentIndex) => {
      // 0 - highest education level
      // 1 - combination in Bachelor/master
      // 2 - % in 12/ graduation
      // 3 - years of work exp
      // 4 - industry
      // 5 - salary range
      // 6 - outcome
      // 7 - how pay
      // 8 - when course start

      if (currentIndex === 2) {
        return (
          <div
            className={clsx('flex', 'align-center', 'justify-center')}
            style={{ border: '0px solid green', height: '200px' }}
          >
            <form>
              <div
                className={clsx('flex', 'justify-center')}
                style={{ border: '0px solid pink' }}
              >
                <input
                  type="number"
                  name="percentage"
                  id="percentage"
                  required
                  style={{ border: 'none', height: '60px', fontSize: '36px' }}
                  onChange={() => {
                    cardSelected('TEMP', curQ);
                  }}
                />
              </div>
            </form>
          </div>
        );
      } else if ([3, 4].includes(currentIndex)) {
        return (
          <div style={{ border: '0px solid green', height: '200px' }}>
            {[0, 1].map((rowIndex) => (
              <Row key={`col${rowIndex}`}>
                {data[curQ]['response']
                  .slice(
                    rowIndex * Math.ceil(data[curQ]['response'].length / 2),
                    (rowIndex + 1) *
                      Math.ceil(data[curQ]['response'].length / 2),
                  )
                  .map((item, index) => (
                    <Col key={`col${index}`}>
                      <div
                        onKeyDown={() => {
                          cardSelected(item, curQ);
                          setSelectedCard(`${rowIndex}${index}`);
                        }}
                        className={
                          isExperienceTab(item)
                            ? clsx(
                                styles.fontRusso,
                                styles.fSize26,
                                styles.dynamicOption,
                              )
                            : clsx(styles.dynamicOption)
                        }
                        onClick={() => {
                          cardSelected(item, curQ);
                          setSelectedCard(`${rowIndex}${index}`);
                        }}
                        style={{
                          // border:
                          //   selectedCard == `${rowIndex}${index}`
                          //     ? "10px solid #000"
                          //     : "6px solid forestgreen",
                          borderRadius:
                            selectedCard === `${rowIndex}${index}`
                              ? '0.5rem'
                              : 'none',
                          background:
                            selectedCard === `${rowIndex}${index}`
                              ? '#FFF'
                              : '',
                          boxShadow:
                            selectedCard === `${rowIndex}${index}`
                              ? `6px 6px 12px 0px rgba(0, 0, 0, 0.25)`
                              : 'none',
                        }}
                      >
                        {getSvg(item, `${rowIndex}${index}`) && (
                          <div
                            className={clsx('flex', 'justify-center')}
                            style={{ border: '0px solid red', height: '40px' }}
                          >
                            {getSvg(item, `${rowIndex}${index}`)}
                          </div>
                        )}
                        {getText(item)}
                      </div>
                    </Col>
                  ))}
              </Row>
            ))}
          </div>
        );
      } else {
        return data[curQ]['response'].map((choice, index) => {
          return cardBasedonChoice(choice, index);
        });
      }
    };

    const cardSelected = (choice, currentIndex) => {
      let valueToUpdate = choice;

      if (currentIndex === 0) {
        setBachelorOrMaster(choice);
      }
      if (currentIndex === 2) {
        const currentPercentage = document.getElementById('percentage').value;
        if (
          currentPercentage &&
          currentPercentage < 0 &&
          currentPercentage > 100
        ) {
          setUserChoiceMade((prev) => {
            const updateStateArray = [...prev];
            updateStateArray[currentIndex] = false;
            return updateStateArray;
          });
          return;
        }

        valueToUpdate = currentPercentage;
      }

      setCapturedData((prev) => {
        const existing = [...prev];
        existing[currentIndex] = valueToUpdate;
        return existing;
      });

      setUserChoiceMade((prev) => {
        const updateStateArray = [...prev];
        updateStateArray[currentIndex] = true;
        return updateStateArray;
      });
    };
    const cardBasedonChoice = (choice, index) => {
      return (
        <div
          onKeyDown={() => {
            cardSelected(choice, curQ);
            setSelectedCard(index);
          }}
          style={{
            // border:
            //   selectedCard == index ? "10px solid #000" : "2px solid pink",
            borderRadius: selectedCard === index ? '0.5rem' : 'none',
            background: selectedCard === index ? '#FFF' : '',
            boxShadow:
              selectedCard === index
                ? `6px 6px 12px 0px rgba(0, 0, 0, 0.25)`
                : 'none',
          }}
          className={styles.option}
          id={`qw${index}`}
          onClick={() => {
            cardSelected(choice, curQ);
            setSelectedCard(index);
          }}
        >
          {getSvg(choice, index) && (
            <div
              className={clsx('flex', 'justify-center')}
              style={{ border: '0px solid red', height: '80px' }}
            >
              {getSvg(choice, index)}
            </div>
          )}

          <h3
            className={
              isExperienceTab(choice)
                ? clsx(styles.fontRusso, styles.fSize26)
                : clsx('')
            }
          >
            {getText(choice)}
          </h3>
        </div>
      );
    };

    const getTitle = (existingTitle, currentIndex) => {
      if (currentIndex === 1) {
        return existingTitle.replace('PLACEHOLDER', bachelorOrMaster);
      }

      if (currentIndex === 2) {
        const nextHighestQualification =
          bachelorOrMaster === HIGHESTEDUCATION.MASTERS ? 'Graduation' : '12th';
        return existingTitle.replace('PLACEHOLDER', nextHighestQualification);
      }

      return existingTitle;
    };

    return (
      <>
        <div
          className={clsx(getStyle(), 'w-100', 'flex')}
          style={{ border: '0px solid green' }}
        >
          <Header university={uni} />
          <Row
            className={clsx('w-100', 'flex', 'justify-center')}
            style={{ border: '0px solid yellow', margin: '90px 0 0 0' }}
            debug={true}
          >
            <Col style={{ border: '0px solid pink' }}>
              <Row
                style={{ border: '0px solid black' }}
                className={styles.container}
              >
                <Row
                  style={{ border: '0px solid yellow' }}
                  className={styles.stepIndicator}
                >
                  <div
                    style={{ border: '0px solid black' }}
                    className={clsx('flex', 'justify-center', 'w-100')}
                  >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((num, index) => {
                      return (
                        <div
                          key={`q${index}`}
                          className={
                            num === curQ
                              ? clsx(styles.step, styles.active)
                              : styles.step
                          }
                        ></div>
                      );
                    })}
                  </div>
                </Row>
              </Row>
              <Row>
                <div className={clsx('w-100', 'flex', 'justify-center')}>
                  {data && data[curQ] && data[curQ]['qm'] && (
                    <h1 className={styles.title}>
                      {' '}
                      {getTitle(data[curQ]['qm'], curQ)}
                    </h1>
                  )}
                </div>
              </Row>
              <Row>
                {data[curQ]['qs'] && (
                  <div className={clsx('w-100', 'flex', 'justify-center')}>
                    {data && data[curQ] && data[curQ]['qs'] && (
                      <p className={styles.subtitle}> {data[curQ]['qs']} </p>
                    )}
                  </div>
                )}
              </Row>
              <Row>
                <div
                  style={{
                    border: '0px solid red',
                    margin: '0',
                  }}
                  className={clsx('flex', 'justify-center', styles.options)}
                >
                  {renderCardBasedOnTab(curQ)}
                </div>
              </Row>
              <Row>
                <div
                  className={clsx('flex', 'justify-center', 'w-100')}
                  style={{ border: '0px solid red', marginTop: '30px' }}
                >
                  <button
                    disabled={!userChoiceMade[curQ]}
                    onClick={buttonClicked}
                    className={
                      userChoiceMade[curQ]
                        ? styles.nextButton
                        : clsx(styles.nextButton, styles.nextButtonDisabled)
                    }
                  >
                    {submitOrNextText}
                  </button>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  };
  return (
    <LayoutContainer style={{ border: '0px solid green' }}>
      <Main style={{ border: '0px solid black' }}>{renderMain()}</Main>
    </LayoutContainer>
  );
};

export default Persona;
