import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';
import clsx from 'clsx';
import { useState } from 'react';
import { Row, Col } from 'react-grid-system';

import Header from '../components/Header';
import LayoutContainer from '../components/LayoutContainer';
import Main from '../components/Main';
import {
  TOKEN_ENDPOINT_URL,
  LEAD_ENDPOINT_URL,
  LOCALSTORAGE,
} from '../config/constants';
import styles from '../styles/Thankyou.module.scss';

import { getAccessToken } from './getAccessToken';
import { getStyle } from './util';

const REFERRER = {
  GOOGLE: 'GOOGLE',
  WEBSITE: 'WEBSITE',
  NEWSPAPER: 'NEWSPAPER',
  WORDOFMOUTH: 'WORDOFMOUTH',
  OTHER: 'OTHER',
};

const Thankyou = () => {
  //const [submitDisabled,setSubmitDisabled] = useState(true)
  const [open, setOpen] = useState(false);
  const submitClicked = async () => {
    let getToKnow = null;
    let getToKnowOther = null;
    let willRecommend = null;

    const choices = [
      'contactChoice1',
      'contactChoice2',
      'contactChoice3',
      'contactChoice4',
      'contactChoice5',
    ];

    choices.forEach((id) => {
      if (document.getElementById(id).checked) {
        if (id === 'contactChoice5') {
          getToKnowOther = document.getElementById('anyotherinput').value;
        }
        getToKnow = document.getElementById(id).value;
      }
    });

    const rchoice = ['recommendationyes', 'recommendationno'];
    rchoice.forEach((id) => {
      if (document.getElementById(id).checked) {
        willRecommend = document.getElementById(id).value;
      }
    });

    if (!willRecommend) {
      return;
    }

    if (!getToKnow) {
      return;
    }

    if (
      ![
        REFERRER.GOOGLE,
        REFERRER.NEWSPAPER,
        REFERRER.WEBSITE,
        REFERRER.WORDOFMOUTH,
      ].includes(getToKnow)
    ) {
      if (getToKnowOther === '') {
        return;
      }
    }

    let leadPersonaDetails = localStorage.getItem(
      LOCALSTORAGE.LEAD_PERSONA_DETAILS,
    );
    leadPersonaDetails = JSON.parse(leadPersonaDetails);

    leadPersonaDetails['surveyQuestions-getToKnow'] = getToKnow;
    leadPersonaDetails['surveyQuestions-getToKnowOther'] = getToKnowOther;
    leadPersonaDetails['surveyQuestions-willRecommend'] = willRecommend;

    const accessToken = await getAccessToken('thankyou');

    if (accessToken) {
      let apiDataToBeSentBack = localStorage.getItem(
        LOCALSTORAGE.API_DATA_TO_BE_SENT_BACK,
      );
      apiDataToBeSentBack = JSON.parse(apiDataToBeSentBack);
      const leadId = localStorage.getItem(LOCALSTORAGE.LEAD_ID);
      const toBeSentData = Object.assign(
        {},
        {
          leadPersonaDetails: leadPersonaDetails,
        },
        apiDataToBeSentBack,
      );

      console.log('>>>>> toBeSentData ', toBeSentData);
      const url = `${LEAD_ENDPOINT_URL}${leadId}`;
      axios
        .put(url, toBeSentData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          setOpen(true);
        })
        .catch(() => {});
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const uni = localStorage.getItem('uni');

  const renderMain = () => {
    return (
      <div
        className={clsx(getStyle(), 'w-100', 'flex')}
        style={{ border: '0px solid green' }}
      >
        <Header university={uni} />
        <Row
          className={clsx('w-100', 'flex', 'justify-center')}
          style={{
            border: '0px solid yellow',
            margin: '90px 0 0 0',
            padding: '0 160px',
          }}
          debug={true}
        >
          <Col style={{ border: '0px solid pink', margin: '2rem 0 0 0' }}>
            <Row
              style={{
                border: '0px solid pink',
                fontSize: '1.25rem',
                color: '#fff',
              }}
            >
              Your answers are appreciated
            </Row>
            <Row
              style={{
                border: '0px solid pink',
                fontSize: '4rem',
                color: '#fff',
              }}
            >
              THANK YOU!
            </Row>
            <Row
              style={{
                border: '0px solid pink',
                fontSize: '1.25rem',
                color: '#fff',
                margin: '16px 0 16px 0',
              }}
            >
              Please feel free to answer a few survey questions
            </Row>
            <Row
              gutterWidth={0}
              style={{
                border: '0px solid pink',
                color: '#fff',
                margin: '0 20px',
              }}
            >
              <div>
                <Row>
                  <div style={{ fontSize: '1.125 rem', margin: '0 0 12px 0' }}>
                    1. How did you get to know about us?
                  </div>
                </Row>
                <Row>
                  <div style={{ fontSize: '1rem' }}>
                    <div
                      style={{
                        dislay: 'flex',
                        justifyContent: 'center',
                        margin: '0 0 8px 0',
                      }}
                    >
                      <input
                        type="radio"
                        id="contactChoice1"
                        name="contact"
                        value="WEBSITE"
                      />
                      <label
                        style={{ marginLeft: '4px' }}
                        htmlFor="contactChoice1"
                      >
                        Website
                      </label>
                    </div>
                    <div style={{ margin: '0 0 8px 0' }}>
                      <input
                        type="radio"
                        id="contactChoice2"
                        name="contact"
                        value="GOOGLE"
                      />
                      <label
                        style={{ marginLeft: '4px' }}
                        htmlFor="contactChoice2"
                      >
                        Google
                      </label>
                    </div>
                    <div style={{ margin: '0 0 8px 0' }}>
                      <input
                        type="radio"
                        id="contactChoice3"
                        name="contact"
                        value="NEWSPAPER"
                      />
                      <label
                        style={{ marginLeft: '4px' }}
                        htmlFor="contactChoice3"
                      >
                        Newspaper
                      </label>
                    </div>
                    <div style={{ margin: '0 0 8px 0' }}>
                      <input
                        type="radio"
                        id="contactChoice4"
                        name="contact"
                        value="WORDOFMOUTH"
                      />
                      <label
                        style={{ marginLeft: '4px' }}
                        htmlFor="contactChoice4"
                      >
                        Word of Mouth
                      </label>
                    </div>
                    <div style={{ margin: '0 0 8px 0' }}>
                      <input
                        type="radio"
                        id="contactChoice5"
                        name="contact"
                        value="other"
                      />
                      <label
                        style={{ marginLeft: '4px' }}
                        htmlFor="contactChoice5"
                      >
                        Any Other ()
                      </label>
                    </div>
                    <div style={{ margin: '0 0 24px 0' }}>
                      <div style={{ margin: '0 0 8px 0' }}>Other</div>
                      <div style={{ width: '240px', border: '0' }}>
                        <input
                          type="text"
                          id="anyotherinput"
                          placeholder="Please enter other resource"
                          className={styles.inputOther}
                        ></input>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </Row>
            <Row
              gutterWidth={0}
              style={{
                border: '0px solid pink',
                color: '#fff',
                margin: '0 20px',
              }}
            >
              <div>
                <Row>
                  <div style={{ fontSize: '1.125rem', margin: '0 0 12px 0' }}>
                    2. Do you want to recommend any family or friends to our
                    program?
                  </div>
                </Row>
                <Row>
                  <div style={{ fontSize: '1rem' }}>
                    <div style={{ margin: '0 0 8px 0' }}>
                      <input
                        type="radio"
                        id="recommendationyes"
                        name="recommendation"
                        value="yes"
                      />
                      <label
                        style={{ marginLeft: '4px' }}
                        htmlFor="recommendationyes"
                      >
                        Yes
                      </label>
                    </div>
                    <div style={{ margin: '0 0 8px 0' }}>
                      <input
                        type="radio"
                        id="recommendationno"
                        name="recommendation"
                        value="no"
                      />
                      <label
                        style={{ marginLeft: '4px' }}
                        htmlFor="recommendationno"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </Row>
              </div>
            </Row>
            <Row gutterWidth={0}>
              <div style={{ margin: '24px 10px', height: '36px' }}>
                <button onClick={submitClicked} className={styles.submitButton}>
                  Submit
                </button>
                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  message="Submitted"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <LayoutContainer style={{ border: '0px solid green' }}>
      <Main style={{ border: '0px solid black' }}>{renderMain()}</Main>
    </LayoutContainer>
  );
};

export default Thankyou;
